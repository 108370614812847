/* Basic editor styles */
.ProseMirror > * + * {
  margin-top: 0.75em;
}
.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
  line-height: 1.1;
}
.mention {
  color: #a975ff;
  background-color: rgba(169, 117, 255, 0.1);
  border-radius: 0.3rem;
  padding: 0.1rem 0.3rem;
}
.character-count {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  color: #68cef8;
}
.character-count--warning {
  color: #fb5151;
}
.character-count__graph {
  margin-right: 0.5rem;
}
.character-count__text {
  color: #868e96;
}
.items {
  position: relative;
  border-radius: 0.25rem;
  background: white;
  color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  font-size: 0.9rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0px 10px 20px rgba(0, 0, 0, 0.1);
}
.item {
  display: block;
  width: 100%;
  text-align: left;
  background: transparent;
  border: none;
  padding: 0.2rem 0.5rem;
}
.item.is-selected,
.item:hover {
  color: #a975ff;
  background: rgba(169, 117, 255, 0.1);
}

.mention::after {
  content: '}}';
}

.card {
  flex-basis: 33%;
  min-height: 318px;
}

.brand-icon {
  position: absolute;
  left: 24px;
  top: 0;
  transform: translateY(-50%);
  background: #3466e2;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.brand-icon svg {
  width: 20px;
}

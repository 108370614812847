/* that file is used by pdf service */

/* Table-specific styling */
.ProseMirror table {
  border-collapse: collapse;
  table-layout: auto;
  width: 100%;
  margin: 0;
  overflow: hidden;
}

.ProseMirror table td,
.ProseMirror table th {
  min-width: 1em;
  border: 1px solid #ced4da;
  padding: 3px 5px;
  vertical-align: top;
  box-sizing: border-box;
  position: relative;
}

.ProseMirror table td > *,
.ProseMirror table th > * {
  margin-bottom: 0 !important;
}

.ProseMirror p {
  margin-bottom: 0 !important;
}

table p {
  min-height: 0;
}

.ProseMirror table th {
  font-weight: bold;
  text-align: left;
  background-color: #f1f3f5;
}

.ProseMirror table .selectedCell:after {
  z-index: 2;
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(200, 200, 255, 0.4);
  pointer-events: none;
}

.ProseMirror table .column-resize-handle {
  position: absolute;
  right: -2px;
  top: 0;
  bottom: -2px;
  width: 4px;
  background-color: #adf;
  pointer-events: none;
}

.tableWrapper {
  overflow-x: auto;
  max-width: 608px;
}

.tableWrapper table {
  max-width: 608px;
}

.eoc_binder .tableWrapper table,
.eoc_open_market .tableWrapper table {
  min-width: 608px !important;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

.editor {
  min-width: 768px;
  max-height: calc(100% - 64px);
  overflow-y: hidden;
  background: #e5e7eb;
}

.editor .editor-content {
  overflow-y: auto;
}

.editor .editor-content .ProseMirror {
  min-height: calc(100vh - 250px);
}

.editor-preview {
  overflow-y: auto;
  max-height: calc(100% - 64px) !important;
}

td[colspan='20'] {
  border-bottom: 1px solid rgb(136, 99, 99) !important;
}

td[colspan='20'] p {
  height: 0 !important;
  min-height: 0 !important;
}

/* lists styling */

li {
  list-style: none;
}

ol[indent='0'] {
  list-style: none !important;
}

ol[type='1.1'] > li {
  padding-left: 2em;
}

ol[type='1.1'] > li > ol[type='1.1'] > li {
  padding-left: 3em;
}

ol[type='1.1'] > li > ol[type='1.1'] > li > ol[type='1.1'] > li {
  padding-left: 4em;
}

ol[type='1.1'] > li > ol[type='1.1'] > li > ol[type='1.1'] > li > ol[type='1.1'] > li {
  padding-left: 5em;
}

/* lists 1 */

ol[type='1'] {
  counter-reset: leadingDecimal;
}

ol[type='1'] > li::before {
  counter-increment: leadingDecimal;
  content: counter(leadingDecimal, decimal) '.';
}

/* lists 1.1 */

ol[type='1.1'] {
  counter-reset: subsequentDecimal;
}

ol[type='1.1'] > li::before {
  counter-increment: subsequentDecimal;
  content: counter(leadingDecimal, decimal) '.' counters(subsequentDecimal, '.') '.';
}

/* lists a */

ol[type='a'] {
  counter-reset: letter;
}

ol[type='a'] > li::before {
  counter-increment: letter;
  content: counter(letter, lower-alpha) '.';
}

/* lists i */

ol[type='i'] {
  counter-reset: romanian;
}

ol[type='i'] > li::before {
  counter-increment: romanian;
  content: counter(romanian, lower-roman) '.';
}

/* lists - counter reset */

ol[indent='0'][start='2'] {
  counter-reset: leadingDecimal 1 letter 1 romanian 1;
}

ol[indent='0'][start='3'] {
  counter-reset: leadingDecimal 2 letter 2 romanian 2;
}

ol[indent='0'][start='4'] {
  counter-reset: leadingDecimal 3 letter 3 romanian 3;
}

ol[indent='0'][start='5'] {
  counter-reset: leadingDecimal 4 letter 4 romanian 4;
}

ol[indent='0'][start='6'] {
  counter-reset: leadingDecimal 5 letter 5 romanian 5;
}

ol[indent='0'][start='7'] {
  counter-reset: leadingDecimal 6 letter 6 romanian 6;
}

ol[indent='0'][start='8'] {
  counter-reset: leadingDecimal 7 letter 7 romanian 7;
}

ol[indent='0'][start='9'] {
  counter-reset: leadingDecimal 8 letter 8 romanian 8;
}

ol[indent='0'][start='10'] {
  counter-reset: leadingDecimal 9 letter 9 romanian 9;
}

ol[indent='0'][start='11'] {
  counter-reset: leadingDecimal 10 letter 10 romanian 10;
}

ol[indent='0'][start='12'] {
  counter-reset: leadingDecimal 11 letter 11 romanian 11;
}

ol[indent='0'][start='13'] {
  counter-reset: leadingDecimal 12 letter 12 romanian 12;
}

ol[indent='0'][start='14'] {
  counter-reset: leadingDecimal 13 letter 13 romanian 13;
}

ol[indent='0'][start='15'] {
  counter-reset: leadingDecimal 14 letter 14 romanian 14;
}

ol[indent='0'][start='16'] {
  counter-reset: leadingDecimal 15 letter 15 romanian 15;
}

ol[indent='0'][start='17'] {
  counter-reset: leadingDecimal 16 letter 16 romanian 16;
}

ol[indent='0'][start='18'] {
  counter-reset: leadingDecimal 17 letter 17 romanian 17;
}

ol[indent='0'][start='19'] {
  counter-reset: leadingDecimal 18 letter 18 romanian 18;
}

ol[indent='0'][start='20'] {
  counter-reset: leadingDecimal 19 letter 19 romanian 19;
}

ol[indent='0'][start='21'] {
  counter-reset: leadingDecimal 20 letter 20 romanian 20;
}

ol[indent='0'][start='22'] {
  counter-reset: leadingDecimal 21 letter 21 romanian 21;
}

ol[indent='0'][start='23'] {
  counter-reset: leadingDecimal 22 letter 22 romanian 22;
}

ol[indent='0'][start='24'] {
  counter-reset: leadingDecimal 23 letter 23 romanian 23;
}

ol[indent='0'][start='25'] {
  counter-reset: leadingDecimal 24 letter 24 romanian 24;
}

ol[indent='0'][start='26'] {
  counter-reset: leadingDecimal 25 letter 25 romanian 25;
}

ol[indent='0'][start='27'] {
  counter-reset: leadingDecimal 26 letter 26 romanian 26;
}

ol[indent='0'][start='28'] {
  counter-reset: leadingDecimal 27 letter 27 romanian 27;
}

ol[indent='0'][start='29'] {
  counter-reset: leadingDecimal 28 letter 28 romanian 28;
}

ol[indent='0'][start='30'] {
  counter-reset: leadingDecimal 29 letter 29 romanian 29;
}

ol[indent='0'][start='31'] {
  counter-reset: leadingDecimal 30 letter 30 romanian 30;
}

ol[indent='0'][start='32'] {
  counter-reset: leadingDecimal 31 letter 31 romanian 31;
}

ol[indent='0'][start='33'] {
  counter-reset: leadingDecimal 32 letter 32 romanian 32;
}

ol[indent='0'][start='34'] {
  counter-reset: leadingDecimal 33 letter 33 romanian 33;
}

ol[indent='0'][start='35'] {
  counter-reset: leadingDecimal 34 letter 34 romanian 34;
}

ol[indent='0'][start='36'] {
  counter-reset: leadingDecimal 35 letter 35 romanian 35;
}

ol[indent='0'][start='37'] {
  counter-reset: leadingDecimal 36 letter 36 romanian 36;
}

ol[indent='0'][start='38'] {
  counter-reset: leadingDecimal 37 letter 37 romanian 37;
}

ol[indent='0'][start='39'] {
  counter-reset: leadingDecimal 38 letter 38 romanian 38;
}

ol[indent='0'][start='40'] {
  counter-reset: leadingDecimal 39 letter 39 romanian 39;
}

ol[indent='0'][start='41'] {
  counter-reset: leadingDecimal 40 letter 40 romanian 40;
}

ol[indent='0'][start='42'] {
  counter-reset: leadingDecimal 41 letter 41 romanian 41;
}

ol[indent='0'][start='43'] {
  counter-reset: leadingDecimal 42 letter 42 romanian 42;
}

ol[indent='0'][start='44'] {
  counter-reset: leadingDecimal 43 letter 43 romanian 43;
}

ol[indent='0'][start='45'] {
  counter-reset: leadingDecimal 44 letter 44 romanian 44;
}

ol[indent='0'][start='46'] {
  counter-reset: leadingDecimal 45 letter 45 romanian 45;
}

ol[indent='0'][start='47'] {
  counter-reset: leadingDecimal 46 letter 46 romanian 46;
}

ol[indent='0'][start='48'] {
  counter-reset: leadingDecimal 47 letter 47 romanian 47;
}

ol[indent='0'][start='49'] {
  counter-reset: leadingDecimal 48 letter 48 romanian 48;
}

ol[indent='0'][start='50'] {
  counter-reset: leadingDecimal 49 letter 49 romanian 49;
}

ol[indent='0'][start='51'] {
  counter-reset: leadingDecimal 50 letter 50 romanian 50;
}

ol[indent='0'][start='52'] {
  counter-reset: leadingDecimal 51 letter 51 romanian 51;
}

ol[indent='0'][start='53'] {
  counter-reset: leadingDecimal 52 letter 52 romanian 52;
}

ol[indent='0'][start='54'] {
  counter-reset: leadingDecimal 53 letter 53 romanian 53;
}

ol[indent='0'][start='55'] {
  counter-reset: leadingDecimal 54 letter 54 romanian 54;
}

ol[indent='0'][start='56'] {
  counter-reset: leadingDecimal 55 letter 55 romanian 55;
}

ol[indent='0'][start='57'] {
  counter-reset: leadingDecimal 56 letter 56 romanian 56;
}

ol[indent='0'][start='58'] {
  counter-reset: leadingDecimal 57 letter 57 romanian 57;
}

ol[indent='0'][start='59'] {
  counter-reset: leadingDecimal 58 letter 58 romanian 58;
}

ol[indent='0'][start='60'] {
  counter-reset: leadingDecimal 59 letter 59 romanian 59;
}

ol[indent='0'][start='61'] {
  counter-reset: leadingDecimal 60 letter 60 romanian 60;
}

ol[indent='0'][start='62'] {
  counter-reset: leadingDecimal 61 letter 61 romanian 61;
}

ol[indent='0'][start='63'] {
  counter-reset: leadingDecimal 62 letter 62 romanian 62;
}

ol[indent='0'][start='64'] {
  counter-reset: leadingDecimal 63 letter 63 romanian 63;
}

ol[indent='0'][start='65'] {
  counter-reset: leadingDecimal 64 letter 64 romanian 64;
}

ol[indent='0'][start='66'] {
  counter-reset: leadingDecimal 65 letter 65 romanian 65;
}

ol[indent='0'][start='67'] {
  counter-reset: leadingDecimal 66 letter 66 romanian 66;
}

ol[indent='0'][start='68'] {
  counter-reset: leadingDecimal 67 letter 67 romanian 67;
}

ol[indent='0'][start='69'] {
  counter-reset: leadingDecimal 68 letter 68 romanian 68;
}

ol[indent='0'][start='70'] {
  counter-reset: leadingDecimal 69 letter 69 romanian 69;
}

ol[indent='0'][start='71'] {
  counter-reset: leadingDecimal 70 letter 70 romanian 70;
}

ol[indent='0'][start='72'] {
  counter-reset: leadingDecimal 71 letter 71 romanian 71;
}

ol[indent='0'][start='73'] {
  counter-reset: leadingDecimal 72 letter 72 romanian 72;
}

ol[indent='0'][start='74'] {
  counter-reset: leadingDecimal 73 letter 73 romanian 73;
}

ol[indent='0'][start='75'] {
  counter-reset: leadingDecimal 74 letter 74 romanian 74;
}

ol[indent='0'][start='76'] {
  counter-reset: leadingDecimal 75 letter 75 romanian 75;
}

ol[indent='0'][start='77'] {
  counter-reset: leadingDecimal 76 letter 76 romanian 76;
}

ol[indent='0'][start='78'] {
  counter-reset: leadingDecimal 77 letter 77 romanian 77;
}

ol[indent='0'][start='79'] {
  counter-reset: leadingDecimal 78 letter 78 romanian 78;
}

ol[indent='0'][start='80'] {
  counter-reset: leadingDecimal 79 letter 79 romanian 79;
}

ol[indent='0'][start='81'] {
  counter-reset: leadingDecimal 80 letter 80 romanian 80;
}

ol[indent='0'][start='82'] {
  counter-reset: leadingDecimal 81 letter 81 romanian 81;
}

ol[indent='0'][start='83'] {
  counter-reset: leadingDecimal 82 letter 82 romanian 82;
}

ol[indent='0'][start='84'] {
  counter-reset: leadingDecimal 83 letter 83 romanian 83;
}

ol[indent='0'][start='85'] {
  counter-reset: leadingDecimal 84 letter 84 romanian 84;
}

ol[indent='0'][start='86'] {
  counter-reset: leadingDecimal 85 letter 85 romanian 85;
}

ol[indent='0'][start='87'] {
  counter-reset: leadingDecimal 86 letter 86 romanian 86;
}

ol[indent='0'][start='88'] {
  counter-reset: leadingDecimal 87 letter 87 romanian 87;
}

ol[indent='0'][start='89'] {
  counter-reset: leadingDecimal 88 letter 88 romanian 88;
}

ol[indent='0'][start='90'] {
  counter-reset: leadingDecimal 89 letter 89 romanian 89;
}

ol[indent='0'][start='91'] {
  counter-reset: leadingDecimal 90 letter 90 romanian 90;
}

ol[indent='0'][start='92'] {
  counter-reset: leadingDecimal 91 letter 91 romanian 91;
}

ol[indent='0'][start='93'] {
  counter-reset: leadingDecimal 92 letter 92 romanian 92;
}

ol[indent='0'][start='94'] {
  counter-reset: leadingDecimal 93 letter 93 romanian 93;
}

ol[indent='0'][start='95'] {
  counter-reset: leadingDecimal 94 letter 94 romanian 94;
}

ol[indent='0'][start='96'] {
  counter-reset: leadingDecimal 95 letter 95 romanian 95;
}

ol[indent='0'][start='97'] {
  counter-reset: leadingDecimal 96 letter 96 romanian 96;
}

ol[indent='0'][start='98'] {
  counter-reset: leadingDecimal 97 letter 97 romanian 97;
}

ol[indent='0'][start='99'] {
  counter-reset: leadingDecimal 98 letter 98 romanian 98;
}

ol[indent='0'][start='100'] {
  counter-reset: leadingDecimal 99 letter 99 romanian 99;
}

ol[indent='0'][type='1.1'] {
  counter-reset: leadingDecimal 1 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='1'] {
  counter-reset: leadingDecimal 1 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='2'] {
  counter-reset: leadingDecimal 2 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='3'] {
  counter-reset: leadingDecimal 3 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='4'] {
  counter-reset: leadingDecimal 4 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='5'] {
  counter-reset: leadingDecimal 5 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='6'] {
  counter-reset: leadingDecimal 6 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='7'] {
  counter-reset: leadingDecimal 7 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='8'] {
  counter-reset: leadingDecimal 8 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='9'] {
  counter-reset: leadingDecimal 9 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='10'] {
  counter-reset: leadingDecimal 10 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='11'] {
  counter-reset: leadingDecimal 11 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='12'] {
  counter-reset: leadingDecimal 12 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='13'] {
  counter-reset: leadingDecimal 13 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='14'] {
  counter-reset: leadingDecimal 14 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='15'] {
  counter-reset: leadingDecimal 15 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='16'] {
  counter-reset: leadingDecimal 16 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='17'] {
  counter-reset: leadingDecimal 17 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='18'] {
  counter-reset: leadingDecimal 18 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='19'] {
  counter-reset: leadingDecimal 19 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='20'] {
  counter-reset: leadingDecimal 20 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='21'] {
  counter-reset: leadingDecimal 21 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='22'] {
  counter-reset: leadingDecimal 22 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='23'] {
  counter-reset: leadingDecimal 23 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='24'] {
  counter-reset: leadingDecimal 24 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='25'] {
  counter-reset: leadingDecimal 25 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='26'] {
  counter-reset: leadingDecimal 26 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='27'] {
  counter-reset: leadingDecimal 27 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='28'] {
  counter-reset: leadingDecimal 28 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='29'] {
  counter-reset: leadingDecimal 29 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='30'] {
  counter-reset: leadingDecimal 30 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='31'] {
  counter-reset: leadingDecimal 31 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='32'] {
  counter-reset: leadingDecimal 32 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='33'] {
  counter-reset: leadingDecimal 33 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='34'] {
  counter-reset: leadingDecimal 34 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='35'] {
  counter-reset: leadingDecimal 35 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='36'] {
  counter-reset: leadingDecimal 36 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='37'] {
  counter-reset: leadingDecimal 37 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='38'] {
  counter-reset: leadingDecimal 38 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='39'] {
  counter-reset: leadingDecimal 39 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='40'] {
  counter-reset: leadingDecimal 40 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='41'] {
  counter-reset: leadingDecimal 41 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='42'] {
  counter-reset: leadingDecimal 42 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='43'] {
  counter-reset: leadingDecimal 43 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='44'] {
  counter-reset: leadingDecimal 44 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='45'] {
  counter-reset: leadingDecimal 45 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='46'] {
  counter-reset: leadingDecimal 46 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='47'] {
  counter-reset: leadingDecimal 47 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='48'] {
  counter-reset: leadingDecimal 48 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='49'] {
  counter-reset: leadingDecimal 49 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='50'] {
  counter-reset: leadingDecimal 50 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='51'] {
  counter-reset: leadingDecimal 51 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='52'] {
  counter-reset: leadingDecimal 52 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='53'] {
  counter-reset: leadingDecimal 53 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='54'] {
  counter-reset: leadingDecimal 54 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='55'] {
  counter-reset: leadingDecimal 55 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='56'] {
  counter-reset: leadingDecimal 56 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='57'] {
  counter-reset: leadingDecimal 57 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='58'] {
  counter-reset: leadingDecimal 58 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='59'] {
  counter-reset: leadingDecimal 59 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='60'] {
  counter-reset: leadingDecimal 60 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='61'] {
  counter-reset: leadingDecimal 61 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='62'] {
  counter-reset: leadingDecimal 62 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='63'] {
  counter-reset: leadingDecimal 63 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='64'] {
  counter-reset: leadingDecimal 64 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='65'] {
  counter-reset: leadingDecimal 65 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='66'] {
  counter-reset: leadingDecimal 66 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='67'] {
  counter-reset: leadingDecimal 67 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='68'] {
  counter-reset: leadingDecimal 68 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='69'] {
  counter-reset: leadingDecimal 69 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='70'] {
  counter-reset: leadingDecimal 70 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='71'] {
  counter-reset: leadingDecimal 71 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='72'] {
  counter-reset: leadingDecimal 72 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='73'] {
  counter-reset: leadingDecimal 73 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='74'] {
  counter-reset: leadingDecimal 74 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='75'] {
  counter-reset: leadingDecimal 75 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='76'] {
  counter-reset: leadingDecimal 76 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='77'] {
  counter-reset: leadingDecimal 77 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='78'] {
  counter-reset: leadingDecimal 78 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='79'] {
  counter-reset: leadingDecimal 79 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='80'] {
  counter-reset: leadingDecimal 80 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='81'] {
  counter-reset: leadingDecimal 81 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='82'] {
  counter-reset: leadingDecimal 82 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='83'] {
  counter-reset: leadingDecimal 83 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='84'] {
  counter-reset: leadingDecimal 84 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='85'] {
  counter-reset: leadingDecimal 85 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='86'] {
  counter-reset: leadingDecimal 86 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='87'] {
  counter-reset: leadingDecimal 87 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='88'] {
  counter-reset: leadingDecimal 88 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='89'] {
  counter-reset: leadingDecimal 89 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='90'] {
  counter-reset: leadingDecimal 90 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='91'] {
  counter-reset: leadingDecimal 91 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='92'] {
  counter-reset: leadingDecimal 92 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='93'] {
  counter-reset: leadingDecimal 93 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='94'] {
  counter-reset: leadingDecimal 94 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='95'] {
  counter-reset: leadingDecimal 95 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='96'] {
  counter-reset: leadingDecimal 96 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='97'] {
  counter-reset: leadingDecimal 97 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='98'] {
  counter-reset: leadingDecimal 98 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='99'] {
  counter-reset: leadingDecimal 99 subsequentDecimal 0;
}

ol[indent='0'][type='1.1'][start='100'] {
  counter-reset: leadingDecimal 100 subsequentDecimal 0;
}

/* that file is used by pdf service */

.mention-cleanup .mention::after {
  content: '';
}

.prose tbody tr {
  border-bottom-width: 0;
}

.prose table {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-bottom: initial !important;
}

.prose tbody td {
  padding-top: initial !important;
  padding-bottom: initial !important;
  padding-left: 5px !important;
}

.prose tbody td:first-child {
  padding-left: 0 !important;
}

.prose table td,
.prose table th {
  border: 1px solid transparent;
}

.prose table p {
  min-height: 0;
}

.lg\:prose-lg p {
  margin-top: 0 !important;
}

/* fix for p margins in editor / not in preview */
.ProseMirror table td > * {
  margin-top: initial !important;
  margin-bottom: 0 !important;
}

.ProseMirror p,
.lg\:prose-lg p {
  margin-bottom: 0 !important;
}

.prose table p:empty,
.ProseMirror p:empty,
.lg\:prose-lg p:empty {
  min-height: 20px !important;
}

/* fix for p margins in editor / not in preview */
.mention-cleanup table > tbody > tr > td > p {
  margin-bottom: 0 !important;
}

.horizontal-rule {
  border-width: 20px;
}

.prose td[colspan='20'] {
  border-bottom: 1px solid #000 !important;
}

.prose td[colspan='20'] p:empty {
  height: 0 !important;
  min-height: 0 !important;
}

.multi-select-wrapper {
  flex-wrap: wrap;
}

.multi-select-wrapper > div {
  margin-bottom: 0.5rem;
}

.multi-select {
  min-height: 42px;
}

.admin .multi-select {
  width: 250px;
}

.loader {
  height: calc(100vh - 64px);
}

.search-input {
  border: 1px solid rgb(209, 219, 219) !important;
}

.admin .search-input {
  border: none !important;
}

.wysiwyg-manager-side-panel {
  max-height: calc(100vh - 64px);
}

/* Nicer scrollybars */

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(209, 213, 219, 1);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: rgba(249, 250, 251, 1);
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(209, 213, 219, 1);
  border-radius: 20px;
  border: 3px solid rgba(249, 250, 251, 1);
}

.prose {
  margin-left: auto;
  margin-right: auto;
  max-width: 640px;
}

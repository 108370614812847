.page-block--full,
.page-block--grid {
  padding-top: 1px;
  margin-top: -1px;
}

.page-A4 {
  height: 297mm;
  min-height: 297mm;
  width: 210mm;
}

.page-A4-width {
  width: 210mm;
  min-width: 210mm;
}

p,
ol,
ul,
li {
  margin: 0 !important;
  padding: 0;
}

.no-list-style {
  list-style-type: none !important;
}

.no-list-style > li {
  list-style: none !important;
}

.no-list-style > li::before {
  display: none;
}

.bare-input {
  all: unset;
}
